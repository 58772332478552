import Vue from 'vue'
import router from './router.js'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import VueMeta from 'vue-meta'
export const eventBus = new Vue();
import VueQuillEditor from 'vue-quill-editor'
import { Button } from "vant";
import { Toast } from 'vant'
import { Tab, Tabs } from 'vant'
import { GoodsAction, GoodsActionIcon, GoodsActionButton } from 'vant'
import { NavBar } from 'vant'
import { Search } from 'vant'
import { Dialog } from 'vant'
import { DropdownMenu, DropdownItem } from 'vant'
import { Notify } from 'vant'
import { NoticeBar } from 'vant'
import { SubmitBar } from 'vant'
import { Popup } from 'vant'
import { Lazyload } from 'vant'
import { Swipe, SwipeItem } from 'vant'
import { ShareSheet } from 'vant'
import { Divider } from 'vant'
import { Sidebar, SidebarItem } from 'vant'
import { Loading } from 'vant'
import { Progress } from 'vant'
import { Collapse, CollapseItem } from 'vant'
import { Sku } from 'vant'
import { Field, CellGroup } from 'vant';
import { SwipeCell } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Sticky } from 'vant';
import infiniteScroll from 'vue-infinite-scroll';
import VueLazyload from 'vue-lazyload'
import { Card } from 'vant';




Vue.use(Card);
import { ImagePreview } from 'vant';
Vue.use(ImagePreview);

import VueWechatTitle from 'vue-wechat-title';
Vue.use(VueWechatTitle);

import { extractLink } from '@/utils/utils.js';
import { decimalPrice } from '@/utils/utils.js';
import { calculateProductPrice } from '@/utils/utils.js';

Vue.prototype.$decimalPrice = decimalPrice;
Vue.prototype.$extractLink = extractLink;
Vue.prototype.$calculateProductPrice = calculateProductPrice;

// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import vueCanvasPoster from 'vue-canvas-poster'
Vue.use(vueCanvasPoster)
Vue.use(VueQuillEditor)
Vue.use(VueMeta)
    // 引入分页组件
import Pagination from '@/components/Pagination'
// 注册分页组件
Vue.component('pagination', Pagination)

Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
})
Vue.use(infiniteScroll)
Vue.use(Sticky);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(SwipeCell);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Vant)
Vue.use(Toast)
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(NavBar)
Vue.use(Search)
Vue.use(GoodsAction)
Vue.use(GoodsActionButton)
Vue.use(GoodsActionIcon)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Sku)
Vue.use(Loading)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Divider)
Vue.use(ShareSheet)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(NoticeBar)
Vue.use(Progress)
Vue.use(Dialog)
Vue.use(SubmitBar)
Vue.use(Popup)
Vue.use(Lazyload)
Vue.use(Lazyload, {
    lazyComponent: true,
})
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Notify)

import {
    Popconfirm,

} from 'element-ui';

Vue.use(Popconfirm);
import { Overlay } from 'vant';

Vue.use(Overlay);
Vue.use(ElementUI)
Vue.config.productionTip = false


Vue.prototype.$globalVar = '';

new Vue({
    router,
    store: store,
    render: h => h(App),
}).$mount('#app')


// window.getGlobalSiteName = function() {
//     return 'Your Site Name'; // 在这里替换为获取网站名称的逻辑
// };