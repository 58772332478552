<template>
  <div class="register-page">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="register-container">
      <!-- 账号密码登录 -->
      <div>
        <h2 class="login-title">账号密码注册</h2>
        <van-form @submit="handleRegister">
          <van-cell-group inset>
            <div class="form-group active">
              <van-field
                v-model="loginForm.username"
                name="用户名"
                label="用户名"
                placeholder="请输入用户名"
                :rules="[{ required: true, message: '请填写用户名' }]"
              />
            </div>
            <div class="form-group active">
              <van-field
                v-model="loginForm.password"
                type="password"
                name="密码"
                label="密码"
                placeholder="请输入密码"
                :rules="[{ required: true, message: '请填写密码' }]"
              />
            </div>

            <div class="form-group active">
              <van-field
                v-model="loginForm.checkPass"
                type="password"
                name="确认密码"
                label="确认密码"
                placeholder="请确认密码"
                :rules="[{ required: true, message: '请填写密码' }]"
              />
            </div>
          </van-cell-group>

          <!-- 验证码 -->
          <div style="padding: 0px 20px">
            <Verify
              @success="success"
              :mode="'pop'"
              :captchaType="'clickWord'"
              :imgSize="{ width: '330px', height: '155px' }"
              ref="verify"
            ></Verify>

            <div class="captcha_intelli-control" @click="useVerify">
              <div class="captcha_intelli-tips">
                <span class="captcha_intelli-icon">
                  <img src="@/assets/image/captcha.png" style="width: 22px" />
                </span>

                <span class="captcha_intelli-text">
                  <template v-if="!verifyStatus"> 点击完成验证 </template>
                  <template v-else> 验证成功 </template>
                </span>
              </div>
            </div>
          </div>

          <div style="margin: 16px">
            <van-button round block type="primary" native-type="submit">
              立即注册
            </van-button>
          </div>
        </van-form>
      </div>

      <div class="rigest">
        <span>已有账号？</span>
        <span style="color: #ff594d" @click="handleLogin()">立即登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/style.css";
// import { getGuestToken, getUserId } from "@/utils/auth";
// import Cookies from "js-cookie";
import { Toast } from "vant";
import { mapState } from "vuex";

import Verify from "@/components/verifition/Verify";

export default {
  components: { Verify },
  data() {
    return {
      // getUserId: getUserId(),
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
        phoneNumber: "",
        smsVerificationCode: "",
        platformType: "2",
        //验证码
        validateCaptcha: undefined,
      },
      labelPosition: "top",
      isSuccess: true,
      isLoading: false,
      buttonText: "获取验证码",
      verifyStatus: false,
    };
  },
  computed: {
    ...mapState({
      webSetting: (state) => state.index.webSetting,
    }),
  },
  mounted() {},

  created() {
    //有推广码的话授权
    if (localStorage.getItem("inviterCode")) {
      this.loginForm.inviterCode = localStorage.getItem("inviterCode");
    }
  },

  methods: {
    /**
     * 验证成功
     */
    success(params) {
      //验证成功
      this.verifyStatus = true;

      this.loginForm.validateCaptcha = params.captchaVerification;
    },

    /**
     * 点击验证
     */
    useVerify() {
      this.$refs.verify.show();
    },

    /**
     * 注册
     */
    handleRegister() {
      if (this.loginForm.checkPass !== this.loginForm.password) {
        Toast("两次输入的密码不一致");
        return;
      }

      this.$store
        .dispatch("user/register", this.loginForm)
        .then(() => {
          this.$store.dispatch("user/login", this.loginForm).then(() => {
            Toast.success({
              message: "注册成功",
            });
            this.$router.push("/mindex");
          });
        })
        .catch(() => {});
    },

    /**
     * 手机号注册
     */
    phoneRegister() {
      if (this.inviter_code) {
        this.loginForm.inviterCode = this.inviter_code;
      }
      this.$store
        .dispatch("user/phoneRegister", this.loginForm)
        .then(() => {
          this.$store.dispatch("user/login", this.loginForm).then(() => {
            Toast.success({
              message: "注册成功",
            });
            this.$router.push("/mindex");
          });
        })
        .catch(() => {});
    },

    /**
     * 登录界面
     */

    handleLogin() {
      this.$router.push("/login");
    },

    /**
     * 返回主页
     */
    onClickLeft() {
      // this.$router.push("/my");
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.register-page {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: fixed;
}

.register-container {
  margin-top: 3rem;
}

.login-title {
  color: #1e2331;
  font-weight: 600;
  font-size: 1.3125rem;
  margin: 0;
  padding: 0 20px;
  margin-bottom: 20px;
}

.register-title {
  color: #1e2331;
  font-weight: 600;
  font-size: 1.3125rem;
  margin: 0;
  padding: 0 20px;
}

.register-page .other {
  width: 19.3125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.625rem;
  color: #ff594d;
  font-weight: 400;
  font-size: 0.875rem;
}

.register-page .rigest {
  width: 100%;
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.8125rem;
  margin-top: 1.46875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
